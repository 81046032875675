import * as React from "react";
import styled from "styled-components";
import { Link } from 'gatsby';
import Section from "../../components/Section/Section";
import { StoreContext } from "../../context/store-context";
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Header = styled.div`
`
const TitleWrapper = styled.div`
  padding: 0 0 15px;
  border-bottom: 1px solid #383B23;
  display: flex; 
  justify-content: space-between;
  align-items: flex-end;
`

const Title = styled.h1` 
  ${fontstack.default}
  ${type('m-large')}
  color: #383B23;
  font-weight: 300;
  margin: 0;
  
  ${media.large`
    ${type('large')}
    font-weight: 300;
  `}
`

const Navigation = styled.ul` 
  list-style: none;
  margin: 20px 0 0;
  display: flex;
`

const NavigationItem = styled.li` 
  ${fontstack.default}
  ${type('m-body')}
  color: #383B23;
  font-weight: 500;
  border-bottom: ${props => props.active ? '1px solid #383B23' : 'none'};
  &:not(:last-child) {
    margin: 0 30px 0 0;
  }

  ${media.large`
    ${type('body')}
    font-weight: 500;
  `}
`

const LogoutDesktop = styled(NavigationItem)` 
  display: none;
  ${media.large`
    display: block;
  `}
`

const LogoutMobile = styled(NavigationItem)` 
  display: block;
  ${media.large`
    display: none;
  `}
`

const OverflowHidden = styled.div`
  overflow: hidden;
`

const MainWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 80px 0 0;
`

const MainInner = styled.div` 
  width: 100%;

  ${media.large`
    width: 70%;
  `}
`

export default function AccountLayout(props) {
  const { children, active } = props;
  const { logout } = React.useContext(StoreContext);
  return (
    <OverflowHidden>
      <Section contain>
        <Header>
          <TitleWrapper> <Title>My<br/>Account</Title><LogoutMobile><Link to='/' onClick={()=>logout()}>Logout</Link></LogoutMobile></TitleWrapper>
          <Navigation>
            <NavigationItem active={active === '/' ? true : false}><Link to='/account'>Personal Information</Link></NavigationItem>
            <NavigationItem active={active === '/orders' ? true : false}><Link to='/account/orders'>Order History</Link></NavigationItem>
            <LogoutDesktop><Link to='/' onClick={()=>logout()}>Logout</Link></LogoutDesktop>
          </Navigation>
        </Header>
        <MainWrapper>
          <MainInner>
            { children }
          </MainInner>
        </MainWrapper>
      </Section>
    </OverflowHidden>
  )
}