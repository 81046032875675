import React, {useEffect,useState} from "react"
import styled from "styled-components";
import { navigate } from 'gatsby';
import { StoreContext } from "../../context/store-context"
import { useForm } from 'react-hook-form';
import { Layout } from "../../components/Layout/layout"
import { TextField, Errors } from '../../components/Forms/Forms';
import AccountLayout from "./account-layout";
import CallToAction from '../../components/CallToAction/CallToAction';
import { media } from '../../utils/mediaQuery';

const FormGrid = styled.form`
  display: grid;
  grid-template-columns: repeat(1,1fr);
  grid-column-gap: 30px;
  grid-row-gap: 25px;

  ${media.large`
    grid-template-columns: repeat(2,1fr);
  `}
`

const SubmitButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: flex-start ;
`

const CustomText = styled(TextField)`
  margin: 0 0 20px;
  ${media.large`
    margin: 0 0 40px;
  `}
`

export default function AccountPage() {
  const { getCurrentUser, getCustomerObject, toggleLogin, customerUpdate, loading } = React.useContext(StoreContext);
  const { register, handleSubmit, setValue } = useForm();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ Customer, setCustomer ] = useState(null);

  useEffect(()=> {

    if(!Customer) {
      getCustomerObject()
      .then(res => setCustomer(res.data.customer))
      .catch((e)=>{
        navigate("/")
        toggleLogin();
      });
    } else {
      setValue('firstName', Customer.firstName);
      setValue('lastName', Customer.lastName);
      setValue('phone', Customer.phone);
      setValue('email', Customer.email);
    }
    
  },[Customer])

  const onSubmit = (data) => {
    setIsLoading(true);
    customerUpdate(data).then((response)=>{
      setIsLoading(false);
      
    }).catch((e)=>{
      setIsLoading(false);
    })
  }

  return (
    <Layout>
      <AccountLayout active="/">
         <FormGrid onSubmit={handleSubmit(onSubmit)}>
            <CustomText type="text" label="First Name" withBorder {...register('firstName')} />
            <CustomText type="text" label="Last Name" withBorder {...register('lastName')} />
            <CustomText type="text" placeholder="Phone Number" withBorder {...register('phone')} />
            <CustomText type="text" label="Email" withBorder {...register('email',{required: true, pattern: /^\S+@\S+$/i})} />
            <div>
            <SubmitButton>
              <CallToAction isLoading={isLoading}>Save</CallToAction>
            </SubmitButton>
            </div>
          </FormGrid>
      </AccountLayout>
    </Layout>
  )
}
